import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import GMCContext from './GMCContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GMCContext />
);
reportWebVitals();
