import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import axios from "axios";
import { GMCContext } from "../../GMCContext";
import { Link } from "react-router-dom";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";

export default function Members() {
  const { formatAddress } = useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [editAddress, setEditAddress] = useState("");
  const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
  const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
  const [bonusInc, setBonusInc] = useState(false);
  const [bonusPer, setBonusPer] = useState(0);
  const [bonusAmt, setBonusAmt] = useState(0);

  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "adminuser",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
          page: currentPage,
          pageSize: pageSize,
          search,
        })
        .then((res) => {
          setdata(res.data.data);
          setPages(res.data.pages);
          setLoading(false);
          if (res.data.error) {
            return "";
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [pages, pageSize, currentPage]);

  useEffect(() => {
    getData();
  }, [search]);

  const editBonus = (address = "", status = false, dollar = 0, per = 0) => {
    setEditAddress(address);
    setBonusInc(status);
    setBonusAmt(dollar);
    setBonusPer(per);
    setBonusIncomeModal(true);
  };

  const bonusIncomeModalClose = () => {
    setEditAddress("");
    setBonusInc(false);
    setBonusAmt(0);
    setBonusPer(0);
    setBonusIncomeModal(false);
  };

  const saveBonusIncome = async () => {
    try {
      if (editAddress) {
        if (bonusAmt > 0) {
          if (bonusPer > 0) {
            await axios
              .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "bonusincome",
                submethod: "insert",
                status: bonusInc,
                bonus_per: bonusPer,
                locked_dollar: bonusAmt,
                address: editAddress,
                key: process.env.REACT_APP_KEY,
              })
              .then((res) => {
                if (res.data.error) {
                  setBonusIncomeErr(res.data.error);
                } else {
                  setEditAddress("");
                  setBonusAmt(0);
                  setBonusPer(0);
                  setBonusInc(false);
                  setBonusIncomeModal(false);
                  setBonusIncomeErr("");
                }
                getData()
              });
          } else {
            setBonusIncomeErr(
              "Bonus assigned percentage must be grather than 0."
            );
          }
        } else {
          setBonusIncomeErr("Dollar lock amount must be grather than 0.");
        }
      } else {
        setBonusIncomeErr("Address not found.");
      }
    } catch (err) {
      console.log("error : ", err);
      setBonusIncomeErr(err.message);
    }
  };

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">Users</li>
              </ol>
            </div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-center">
              <div className="input-group search-area d-inline-flex me-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="input-group-text"
                    onClick={getData}
                  >
                    <i className="flaticon-381-search-2" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body ">
                    <div className="d-flex justify-content-between">
                      <div className="card-title text-light">Members</div>
                      <span
                        className="btn btn-primary btn-sm "
                        onClick={() => getData()}
                      >
                        <i
                          className="flaticon-381-repeat-1 "
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <hr />
                    <div className="table-responsive">
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Super Reward</th>
                            <th>Status</th>
                            <th>Address</th>
                            <th>Reff Address</th>
                            <th>Staking</th>
                            {/* <th>Withdrawal</th> */}
                            <th>Team</th>
                            {/* <th>Amount</th> */}
                            <th>Date & Time</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data?.length
                            ? data.map((ele, i) => {
                                let ind =
                                  currentPage * pageSize - pageSize + (i + 1);
                                return (
                                  <tr>
                                    <td>{ind}</td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <>
                                          {/* <div className="green-dot-active mx-2"></div>
                                            Active */}
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-sm p-1 px-2 mx-2"
                                            onClick={() =>
                                              editBonus(
                                                ele.address,
                                                ele?.bonus_income,
                                                ele?.dollar_locked,
                                                ele?.bonus_assigned_per
                                              )
                                            }
                                          >
                                            <i
                                              class="fa fa-pencil fs-18"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                          {ele.bonus_income === true ? (
                                          <>
                                            <div className="green-dot-active mx-2"></div>
                                            Active
                                          </>
                                        ) : (
                                          <>
                                            <div className="red-dot-active mx-2"></div>
                                            Deactive
                                          </>
                                        )}
                                        </>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        {ele.status === true ? (
                                          <>
                                            <div className="green-dot-active mx-2"></div>
                                            Active
                                          </>
                                        ) : (
                                          <>
                                            <div className="red-dot-active mx-2"></div>
                                            Deactive
                                          </>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        target="_blank"
                                        className="text-primary"
                                        href={`${process.env.REACT_APP_EXPLORER}address/${ele.address}`}
                                        rel="noreferrer"
                                      >
                                        {formatAddress(ele.address)}
                                      </a>
                                      {ele.address ? (
                                        <Copy data={ele.address} />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      <a
                                        target="_blank"
                                        className="text-primary"
                                        href={`${process.env.REACT_APP_EXPLORER}address/${ele.ref_address}`}
                                        rel="noreferrer"
                                      >
                                        {ele.ref_address
                                          ? formatAddress(ele.ref_address)
                                          : "-"}
                                        &nbsp;
                                      </a>
                                      {ele.ref_address ? (
                                        <Copy data={ele.ref_address} />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      $ {ele.t_staking}
                                      {/* Staking:
                                  <a
                                    target="_blank"
                                    className="text-primary"
                                    href={`${process.env.REACT_APP_EXPLORER}tx/${ele.hash}`}
                                    rel="noreferrer"
                                  >
                                    {" "}
                                    {formatAddress(ele.hash)}
                                  </a>
                                  {ele.hash ? <Copy data={ele.hash} /> : ""}
                                  <br />
                                  Unstake:
                                  <a
                                    target="_blank"
                                    className="text-primary"
                                    href={`${process.env.REACT_APP_EXPLORER}tx/${ele.unstake_hash}`}
                                    rel="noreferrer"
                                  >
                                    {formatAddress(ele.unstake_hash)}
                                  </a>
                                  {ele.unstake_hash ? <Copy data={ele.unstake_hash} /> : ""} */}
                                    </td>
                                    <td>{ele.team}</td>
                                    <td>{ele.createdAt}</td>
                                    <td>
                                      <span className="me-3">
                                        <Link
                                          className="edit-appointment"
                                          type="button"
                                          to={`/userreport/${ele.address}`}
                                        >
                                          <i
                                            className="fa fa-book fs-18 "
                                            aria-hidden="true"
                                          />
                                        </Link>
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !data || !data.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div
        className={`modal fade ${bonusIncomeModal ? "show" : ""}`}
        id="confirmUpd"
        style={bonusIncomeModal ? { display: "block" } : { display: "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Are you sure??</h5>
            </div>
            <div className="modal-body">
              {bonusIncomeErr ? (
                <>
                  <small id="emailHelp" class="form-text text-danger">
                    {bonusIncomeErr}
                  </small>
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  checked={bonusInc}
                  onChange={(e)=>setBonusInc(e.target.checked)}
                />
                <label class="form-check-label" for="exampleCheck1">
                  Check me out
                </label>
              </div>
              <br />
              <div class="form-group">
                <label for="exampleInputEmail1">Dollar Lock Amount</label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="0"
                  value={bonusAmt}
                  onChange={(e) => setBonusAmt(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">
                  Bonus Assigned Percentage
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="0"
                  value={bonusPer}
                  onChange={(e) => setBonusPer(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light px-3 py-2"
                data-bs-dismiss="modal"
                onClick={bonusIncomeModalClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary px-3 py-2"
                data-bs-dismiss="modal"
                onClick={() => saveBonusIncome()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
