import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("odkpfub");
    navigate("/");
  };
  useEffect(() => {
    var checl = localStorage.getItem("odkpfub");
    if (checl !== "5Dg4ZCDAxWoxjgGHRj6BAMQ4dUDC") {
      navigate("/");
    }
  }, [localStorage]);
  return (
    <>
      <div className="nav-header">
        <a href="/" className="brand-logo">
          <img src="/assets/images/logo192.png" width={55} alt="" srcset="" />
          <h2 className="mb-0 d-md-block d-none">Onmax</h2>
        </a>
        <div className="nav-control">
          <div className="hamburger">
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
        </div>
      </div>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="dashboard_bar">ONMAX ADMIN</div>
              </div>
              <ul className="navbar-nav header-right">
                <li className="nav-item dropdown header-profile">
                  <a className="nav-link" role="button" data-bs-toggle="dropdown">
                    <img src="/assets/images/logo192.png" width={20} alt="" />
                    {/* <div className="header-info">
                      <span>
                        Hello,<strong> ONMAX</strong>
                      </span>
                    </div> */}
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a className="dropdown-item ai-icon" type="button" onClick={() => logOut()}>
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-danger"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span className="ms-2">Logout </span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
