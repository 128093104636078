import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import { useParams } from "react-router";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Copy from "../Comman/Copy";
import StakingIncome from "./StakingIncome";
import LevelIncome from "./LevelIncome";
import RankIncome from "./RankIncome";
import CommunityIncome from "./CommunityIncome";
import RoiOfRoi from "./RoiOfRoi";
import Stakings from "./Stakings";
import Unstake from "./Unstake";
import Withdrawal from "./Withdrawal";
import PendingWithdrawal from "./PendingWithdrawal";
import Statements from "./Statements";

export default function MemberReport() {
  const { address } = useParams();
  const { formatAddress } = useContext(GMCContext);
  const [data, setdata] = useState([]);
  const [totReward, setTotReward] = useState(0);

  const getData = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "getbyid",
        key: process.env.REACT_APP_KEY,
        address,
      })
      .then((res) => {
        if (res.data.data) {
          var fbln =
            res.data.data.level_rew +
            res.data.data.staking_rew +
            res.data.data.heritage_rew +
            res.data.data.community_rew +
            res.data.data.community_royalty -
            res.data.data.t_cold_withdrawal -
            res.data.data.t_other_withdrawal;
          setTotReward(fbln);
          setdata(res.data.data);
        }
        if (res.data.error) {
          return "";
        }
      });
  };
  useEffect(() => {
    if (address) {
      getData();
    }
  }, [address]);
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="#">Member Report</a>
                </li>
              </ol>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="profile-statistics">
                  <div className="row">
                    <div className="col-12 col-md-6 mb-4">
                      <p className="text-center w-100">Address</p>
                      <h5 className="m-b-0 text-center">
                        <a
                          target="_blank"
                          className="text-primary"
                          href={`${process.env.REACT_APP_EXPLORER}address/${address}`}
                          rel="noreferrer"
                        >
                          {formatAddress(address)}
                        </a>
                        {address ? <Copy data={address} /> : ""}
                      </h5>
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                      <p className="text-center">Ref Address</p>
                      <h5 className="m-b-0 text-center">
                        {data?.ref_address ? (
                          <>
                            <a
                              target="_blank"
                              className="text-primary"
                              href={`${process.env.REACT_APP_EXPLORER}address/${address}`}
                              rel="noreferrer"
                            >
                              {data?.ref_address ? formatAddress(data?.ref_address) : ""}
                            </a>
                            {data?.ref_address ? <Copy data={data?.ref_address} /> : ""}
                          </>
                        ) : (
                          "-"
                        )}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Validators</p>
                          <h5 className="text-center">{data?.ref_team ? data?.ref_team : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Team</p>
                          <h5 className="text-center">{data?.team ? data?.team : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Stake</p>
                          <h5 className="text-center">$ {data?.t_staking ? data?.t_staking : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Total Reward</p>
                          <h5 className="text-center">$ {totReward}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Staking Reward</p>
                          <h5 className="text-center">$ {data?.staking_rew ? data?.staking_rew : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Aggregator Reward</p>
                          <h5 className="text-center">$ {data?.level_rew ? data?.level_rew : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Cold Reward</p>
                          <h5 className="text-center">$ {data?.heritage_rew ? data?.heritage_rew : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Mining Reward</p>
                          <h5 className="text-center">$ {data?.community_rew ? data?.community_rew : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Rank Reward</p>
                          <h5 className="text-center">$ {data?.community_royalty ? data?.community_royalty : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Strong Leg</p>
                          <h5 className="text-center">$ {data?.next_strong_leg ? data?.next_strong_leg : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Strong Leg Size</p>
                          <h5 className="text-center">
                            {data?.next_strong_leg_users ? data?.next_strong_leg_users : "0"}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Other Leg</p>
                          <h5 className="text-center">$ {data?.next_other_leg ? data?.next_other_leg : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Other Leg Size</p>
                          <h5 className="text-center">
                            {data?.next_other_leg_users ? data?.next_other_leg_users : "0"}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Eligibility</p>
                          <h5 className="text-center">{data?.eligibility ? data?.eligibility : "0"}%</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">C P Withdrawal</p>
                          <h5 className="text-center">$ {data?.t_cold_withdrawal ? data?.t_cold_withdrawal : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">C P Balance</p>
                          <h5 className="text-center">
                            $ {data?.t_cold_pocket_balance ? data?.t_cold_pocket_balance : "0"}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">M P Withdrawal</p>
                          <h5 className="text-center">$ {data?.t_other_withdrawal ? data?.t_other_withdrawal : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">M P Balance</p>
                          <h5 className="text-center">
                            $ {data?.t_other_pocket_balance ? data?.t_other_pocket_balance : "0"}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Total Business</p>
                          <h5 className="text-center">$ {data?.team_business ? data?.team_business : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Today Business</p>
                          <h5 className="text-center">
                            $ {data?.user_today_teambusiness ? data?.user_today_teambusiness : "0"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StakingIncome address={address} />
            <LevelIncome address={address} />
            <RankIncome address={address} />
            <CommunityIncome address={address} />
            <RoiOfRoi address={address} />
            <Stakings address={address} />
            <Unstake address={address} />
            <Withdrawal address={address} />
            <PendingWithdrawal address={address} />
            <Statements address={address} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
