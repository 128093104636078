import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";

export default function PendingWithdrawal({ address }) {
  const { formatAddress, formatDateTime } = useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "adminpendingwithdrawal",
          submethod: "getbyid",
          key: process.env.REACT_APP_KEY,
          address,
          pages: currentPage,
          pageSize: pageSize,
          search: search,
        })
        .then((res) => {
          setdata(res.data.data);
          setPages(res.data.pages);
          setLoading(false);
          if (res.data.error) {
            return "";
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (address) {
      getData();
    }
  }, [address, pageSize, currentPage]);
  useEffect(() => {
    getData();
  }, [search]);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="form-head d-flex align-items-center">
                <div className="input-group search-area d-inline-flex m-3">
                  <input
                    type="text"
                    className="form-control form-control-rev"
                    placeholder="Search here"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button type="button" className="input-group-text input-group-text-rev" onClick={getData}>
                      <i className="flaticon-381-search-2" />
                    </button>
                  </div>
                </div>
              </div>
              <h3 class="py-3 px-5">Pending Withdrawal</h3>
              <hr />
              <div className="table-responsive">
                <table id="example5" className="table table-striped patient-list mb-4 dataTablesCard fs-14">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Address</th>
                      <th>Amount</th>
                      <th>Token</th>
                      <th>Fee</th>
                      <th>Total Recieve</th>
                      <th>Rate</th>
                      <th>Type</th>
                      <th>Datetime</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length
                      ? data.map((e, i) => {
                          let ind = currentPage * pageSize - pageSize + (i + 1);
                          return (
                            <tr>
                              <td>{ind}</td>
                              <td>
                                <a
                                  target="_blank"
                                  className="text-primary"
                                  href={`${process.env.REACT_APP_EXPLORER}address/${e.address}`}
                                  rel="noreferrer"
                                >
                                  {formatAddress(e.address)}
                                </a>
                                {e.address ? <Copy data={e.address} /> : ""}
                              </td>
                              <td>$ {e.amount}</td>
                              <td>
                                {e.token} {process.env.REACT_APP_TICKER}
                              </td>
                              <td>
                                {e.fee} {process.env.REACT_APP_TICKER}
                              </td>
                              <td>
                                {e.t_rcv} {process.env.REACT_APP_TICKER}
                              </td>
                              <td>$ {e.rate}</td>
                              <td>{e.type === 1 ? "Cold Withdrawal" : "Mining Withdrawal"}</td>
                              {/* <td>{e.status}</td> */}
                              <td>{formatDateTime(e.createdAt)}</td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
                {loading ? (
                  <div className="text-center">
                    <p>Loading...</p>
                  </div>
                ) : !data || !data.length ? (
                  <div className="text-center">
                    <p>No data found.</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pages={pages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
